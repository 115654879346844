<template>
  <div class="our-stories l-three-col">
    <Card headlineLevel="h3" heading="Workforce Development &amp; STEM" isSmall headlineDisplayClass="headline5">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/CSR/OurValues/our-stories/idea-committee.webp     352w,
              @/assets/images/CSR/OurValues/our-stories/idea-committee@2x.webp  703w,
              @/assets/images/CSR/OurValues/our-stories/idea-committee@3x.webp 1054w
            "
            sizes="(max-width: 750px) 100vw, (max-width: 1030px) 950px, 384px"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/our-stories/idea-committee.webp"
            alt="Four people walking in pairs of two towards the camera crossing a street, each pair is looking towards each other and smiling."
          />
        </picture>
      </template>
      <p>
        Electrify America supports science, technology, engineering and math (STEM) and workforce development programs
        and to help promote zero emission vehicle (ZEV), technology and infrastructure.
      </p>
      <a href="https://media.electrifyamerica.com/en-us/releases/210" class="button-link" target="_blank"
        >Learn More <span class="sr-only">about Workforce Development &amp; STEM</span> <Arrow class="arrow"
      /></a>
    </Card>
    <Card headlineLevel="h3" heading="Green City" isSmall headlineDisplayClass="headline5">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/CSR/OurValues/our-stories/green-city.webp     352w,
              @/assets/images/CSR/OurValues/our-stories/green-city@2x.webp  703w,
              @/assets/images/CSR/OurValues/our-stories/green-city@3x.webp 1054w
            "
            sizes="(max-width: 750px) 100vw, (max-width: 1030px) 950px, 384px"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/our-stories/green-city.webp"
            alt="Loading dock with shipping containers on ships"
          />
        </picture>
      </template>
      <p>
        Learn how we’re accelerating freight and transit electrification in Southern California’s most bustling port
        locations.
      </p>
      <a href="/green-city" class="button-link"
        >Learn More <span class="sr-only">about Green City</span><Arrow class="arrow"
      /></a>
    </Card>
    <Card headlineLevel="h3" heading="ZEV Education" isSmall headlineDisplayClass="headline5">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/CSR/OurValues/our-stories/zev-education.webp     352w,
              @/assets/images/CSR/OurValues/our-stories/zev-education@2x.webp  703w,
              @/assets/images/CSR/OurValues/our-stories/zev-education@3x.webp 1054w
            "
            sizes="(max-width: 750px) 100vw, (max-width: 1030px) 950px, 384px"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/our-stories/zev-education.webp"
            alt="Woman in a brown jacket to the left of a white sedan with an Electrify America logo on the charger cover, she is plugging a charger into the car."
          />
        </picture>
      </template>
      <p>
        Electrify America promotes ZEV education and access in disadvantaged and low-income communities throughout
        California.
      </p>
      <a href="https://media.electrifyamerica.com/en-us/releases/201" class="button-link" target="_blank"
        >Learn More <span class="sr-only">about ZEV Education</span><Arrow class="arrow"
      /></a>
    </Card>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';
import Card from '@/components/Card/Card.vue';

export default {
  components: {
    Arrow,

    Card,
  },
};
</script>

<style lang="scss">
.our-stories {
  &.l-three-col {
    flex-direction: row;

    @media (max-width: 750px) {
      flex-direction: column;
      width: fit-content;
    }
  }
}
</style>
