<template>
  <div class="case-studies l-three-col">
    <Card headlineLevel="h4" heading="Ecology Action" isSmall headlineDisplayClass="headline5">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/CSR/OurValues/case-studies/ecology-action.webp     352w,
              @/assets/images/CSR/OurValues/case-studies/ecology-action@2x.webp  703w,
              @/assets/images/CSR/OurValues/case-studies/ecology-action@3x.webp 1054w
            "
            sizes="(max-width: 750px) 100vw, (max-width: 1030px) 950px, 384px"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/case-studies/ecology-action.webp"
            alt="Simplified vector graphic of a leaf and an electric plug, the leaf stem and the plug cable are connected at the bottom."
          />
        </picture>
      </template>
      <p>We teamed up with Ecology Action to make an electric vehicle lifestyle a reality for diverse communities.</p>
      <a href="/pdf/CSR/Ecology-Action-Case-Study.pdf" class="button-link" target="_blank"
        >Read Now <span class="sr-only">about Ecology Action (PDF)</span> <Arrow class="arrow"
      /></a>
    </Card>
    <Card headlineLevel="h4" heading="Los Angeles Cleantech Incubator" isSmall headlineDisplayClass="headline5">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/CSR/OurValues/case-studies/LA-cleantech-incubator.webp     352w,
              @/assets/images/CSR/OurValues/case-studies/LA-cleantech-incubator@2x.webp  703w,
              @/assets/images/CSR/OurValues/case-studies/LA-cleantech-incubator@3x.webp 1054w
            "
            sizes="(max-width: 750px) 100vw, (max-width: 1030px) 950px, 384px"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/case-studies/LA-cleantech-incubator.webp"
            alt="Simplified vector graphic of a light bulb with an Electrify America charger icon superimposed in the middle"
          />
        </picture>
      </template>
      <p>
        We funded STEM and workforce development programs to support training for jobs in the EV and charging industry.
      </p>
      <a href="/pdf/CSR/LA-Cleantech-Case-Study.pdf" class="button-link" target="_blank"
        >Read Now <span class="sr-only">about Los Angeles Cleantech Incubator (PDF)</span> <Arrow class="arrow"
      /></a>
    </Card>
    <Card headlineLevel="h4" heading="Valley Clean Air Now" isSmall headlineDisplayClass="headline5">
      <template #image>
        <picture>
          <source
            srcset="
              @/assets/images/CSR/OurValues/case-studies/valley-clean-air-now.webp     352w,
              @/assets/images/CSR/OurValues/case-studies/valley-clean-air-now@2x.webp  703w,
              @/assets/images/CSR/OurValues/case-studies/valley-clean-air-now@3x.webp 1054w
            "
            sizes="(max-width: 750px) 100vw, (max-width: 1030px) 950px, 384px"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/case-studies/valley-clean-air-now.webp"
            alt="Simplified vector graphic of an Electrify America charger icon, surrounded by a curved line on the right and dots on the left that come together to form a circle."
          />
        </picture>
      </template>
      <p>
        We supported Valley CAN in ZEV education and awareness, STEM, and workforce development programs to promote all
        aspects of the EV experience.
      </p>
      <a href="/pdf/CSR/Valley-CAN-Case-Study.pdf" class="button-link" target="_blank"
        >Read Now <span class="sr-only"><span class="sr-only">about Valley Clean Air Now (PDF)</span></span>
        <Arrow class="arrow"
      /></a>
    </Card>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';
import Card from '@/components/Card/Card.vue';

export default {
  components: {
    Arrow,

    Card,
  },
};
</script>

<style lang="scss">
.case-studies {
  &.l-three-col {
    flex-direction: row;

    @media (max-width: 750px) {
      flex-direction: column;
      width: fit-content;
    }
  }
}
</style>
