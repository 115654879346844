<template>
  <div class="l-three-col newsletters">
    <div class="newsletter">
      <a class="headline5" href="/pdf/CSR/Electrify-America-Q4-2022-Newsletter.pdf" target="_blank"
        >Electrify America Q4 2022 Newsletter <span class="sr-only">(PDF)</span><Arrow class="arrow"
      /></a>
    </div>
    <div class="newsletter">
      <a class="headline5" href="/pdf/CSR/Electrify-America-Q3-2022-Newsletter.pdf" target="_blank"
        >Electrify America Q3 2022 Newsletter <span class="sr-only">(PDF)</span><Arrow class="arrow"
      /></a>
    </div>
    <div class="newsletter">
      <a class="headline5" href="/pdf/CSR/Electrify-America-Q2-2022-Newsletter.pdf" target="_blank"
        >Electrify America Q2 2022 Newsletter <span class="sr-only">(PDF)</span><Arrow class="arrow"
      /></a>
    </div>
    <div class="newsletter">
      <a class="headline5" href="/pdf/CSR/Electrify-America-Q4-2021-Newsletter.pdf" target="_blank"
        >Electrify America Q4 2021 Newsletter <span class="sr-only">(PDF)</span><Arrow class="arrow"
      /></a>
    </div>
    <div class="newsletter">
      <a class="headline5" href="/pdf/CSR/Electrify-America-Q3-2021-Newsletter.pdf" target="_blank"
        >Electrify America Q3 2021 Newsletter <span class="sr-only">(PDF)</span><Arrow class="arrow"
      /></a>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';

export default {
  components: {
    Arrow,
  },
};
</script>

<style lang="scss">
.newsletters {
  flex-wrap: wrap;
  justify-content: flex-start;

  .newsletter {
    display: flex;
    @media (min-width: 1030px) {
      width: calc(33.3% - 1rem);
    }

    a {
      background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #35bdd1 95%, #d7df38 0%);
      border-left: 5px solid transparent;
      border-image-slice: 1;
      border-image-source: linear-gradient(to bottom, #35bdd1, #d7df38);
      border-radius: 8px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
      display: block;
      line-height: 1.5;
      padding: var(--flow-space);
      padding-right: calc(var(--flow-space) * 2);
      position: relative;
      width: 100%;

      .arrow {
        position: absolute;
        right: var(--flow-space);
        top: 50%;
        transform: scale(2) translateY(-50%);
      }
    }
  }
}
</style>
