<template>
  <div class="our-values">
    <h1 class="sr-only">Electrify America Our Values Page</h1>
    <Hero heading="Our Values" headlineLevel="h2"
      ><template slot="image">
        <picture>
          <source
            media="(min-width:750px)"
            srcset="
              @/assets/images/CSR/OurValues/hero.webp,
              @/assets/images/CSR/OurValues/hero@2x.webp 2x,
              @/assets/images/CSR/OurValues/hero@3x.webp 3x
            "
          />
          <source
            media="(max-width:749px)"
            srcset="
              @/assets/images/CSR/OurValues/hero-mobile.webp,
              @/assets/images/CSR/OurValues/hero-mobile@2x.webp 2x,
              @/assets/images/CSR/OurValues/hero-mobile@3x.webp 3x
            "
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/hero.webp"
            alt="Electrify America charger in a field at sunset, wrapped in colorful flowers with three butterflies flying nearby."
          /> </picture></template
    ></Hero>
    <TwoColumnContentBlock heading="Unlocking a brighter future" headlineLevel="h3">
      <p>
        At Electrify America, we lead with sustainable innovation, promote equity and diversity, and empower our
        communities to enable accessible electric transportation.
      </p>
    </TwoColumnContentBlock>

    <OurImpact></OurImpact>

    <div class="l-one__col l-one__col--x-wide">
      <Card headlineLevel="h3" heading="Annual report">
        <template #image>
          <picture>
            <source
              srcset="
                @/assets/images/CSR/OurValues/annual-report.webp     361w,
                @/assets/images/CSR/OurValues/annual-report@2x.webp  721w,
                @/assets/images/CSR/OurValues/annual-report@3x.webp 1081w
              "
              sizes="(max-width: 750px) 100vw, 361w"
            />
            <img
              loading="lazy"
              class="image"
              src="@/assets/images/CSR/OurValues/annual-report.webp"
              alt="An Electrify America charging station lit up at sunset, two chargers are visible under a large open-air shelter. In the background is a large rock and the start of a mountain in the distance."
            />
          </picture>
        </template>
        <p>
          Electrify America’s corporate social responsibility (CSR) strategy is centered around staking a position at
          the forefront of the electric vehicle (EV) charging industry by accelerating and expanding access to electric
          mobility across the U.S.
        </p>
        <p>
          <a class="button" href="/pdf/CSR/2022-Annual-Report.pdf" target="_blank"
            >Read the 2022 report <span class="sr-only">(PDF)</span></a
          >
        </p>
        <p>
          <a class="link medium" href="/pdf/CSR/2021-Annual-Report.pdf" target="_blank"
            >Read the 2021 report <span class="sr-only">(PDF)</span></a
          >
        </p>
      </Card>
    </div>

    <div class="l-one__col l-one__col--x-wide">
      <h3>Our Stories</h3>
    </div>
    <OurStories></OurStories>

    <div class="l-one__col l-one__col--x-wide">
      <h3>Insights</h3>
    </div>
    <Insights></Insights>

    <div class="l-one__col l-one__col--x-wide">
      <h3>Case Studies</h3>
    </div>
    <CaseStudies></CaseStudies>

    <div class="l-one__col l-one__col--x-wide">
      <h3>Quarterly newsletters</h3>
      <p>
        <a href="/sign-up/?topic=Sustainability" class="link medium">Subscribe</a> to our quarterly newsletter to stay
        connected. You can unsubscribe at any time.
      </p>
    </div>
    <Newsletters></Newsletters>

    <div class="l-one__col l-one__col--x-wide investment-plan">
      <HeroWithContentOverlay mobileResponsive="above">
        <template #image>
          <picture>
            <source
              media="(min-width:750px)"
              srcset="
                @/assets/images/CSR/OurValues/investment-plan.webp,
                @/assets/images/CSR/OurValues/investment-plan@2x.webp 2x,
                @/assets/images/CSR/OurValues/investment-plan@3x.webp 3x
              "
            />
            <source
              media="(max-width:749px)"
              srcset="
                @/assets/images/CSR/OurValues/investment-plan-mobile.webp,
                @/assets/images/CSR/OurValues/investment-plan-mobile@2x.webp 2x,
                @/assets/images/CSR/OurValues/investment-plan-mobile@3x.webp 3x
              "
            />
            <img
              loading="lazy"
              class="image"
              src="@/assets/images/CSR/OurValues/investment-plan@2x.webp"
              alt="An Electrify America charging station lit up at dusk, four chargers are lined up in two rows of two under a large open-air shelter. In the background is the side of a large mountain and purple darkening sky."
            />
          </picture>
        </template>
        <h3>Our Investment Plan</h3>
        <p>
          Electrify America plans to invest $2 billion in Zero Emission Vehicle (ZEV) infrastructure, access, and
          education programs in the United States.
        </p>
        <p>
          <router-link class="button-link" :to="{ name: `our-plan-en` }">
            Learn more <ArrowWhite class="arrow" style="margin-left: 10px"></ArrowWhite
          ></router-link>
        </p>
      </HeroWithContentOverlay>
    </div>
  </div>
</template>

<script>
import ArrowWhite from '@/assets/images/Home/Arrow_White.svg';
import Card from '@/components/Card/Card.vue';
import CaseStudies from '@/components/CSR/CaseStudies.vue';
import Hero from '@/components/Hero/Hero.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import Insights from '@/components/CSR/Insights.vue';
import Newsletters from '@/components/CSR/Newsletters.vue';
import OurImpact from '@/components/CSR/OurImpact.vue';
import OurStories from '@/components/CSR/OurStories.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  components: {
    ArrowWhite,
    Card,
    CaseStudies,
    Hero,
    HeroWithContentOverlay,
    Insights,
    Newsletters,
    OurImpact,
    OurStories,
    TwoColumnContentBlock,
  },
  name: 'OurValues',
  metaInfo: {
    title: `Corporate Social Responsibility | Electrify America`,
    meta: [
      {
        name: 'description',
        content: `Learn about Electrify America’s commitment to creating a more sustainable and equitable future by investing in our environmental & social practices.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/our-values/' }],
  },
};
</script>

<style lang="scss">
.our-values {
  --flow-space: 1.5rem; // 24px

  h3 {
    font-weight: $fw-light;
  }

  .card {
    padding: 0;
    padding-bottom: calc(var(--flow-space) * 2);

    @media (min-width: 1030px) {
      padding: 0;
    }

    &--small {
      max-width: 500px;
      @media (min-width: 1030px) {
        max-width: 100%;
      }
    }

    img {
      border-radius: 10px;
      max-width: 100%;
      width: 100%;
    }

    .card__container {
      padding: 0;
    }
  }

  .hero__image {
    height: auto;
    max-height: 508px;

    img {
      max-height: 508px;
    }
  }

  .l-one__col,
  .l-two-col,
  .l-three-col {
    gap: var(--flow-space);
    margin: calc(var(--flow-space) * 2.666666) auto;
    padding-left: var(--flow-space);
    padding-right: var(--flow-space);
  }

  .l-one__col {
    @media (min-width: 1030px) {
      margin-top: calc(var(--flow-space) * 5.333333);
    }
  }
}
</style>
