<template>
  <div class="our-impact">
    <div class="slides-container" aria-hidden="true"></div>

    <nav class="our-impact__nav" aria-labelledby="our-impact">
      <div class="our-impact__nav-container">
        <h3 class="headline4" id="our-impact">Our impact</h3>
        <ul>
          <li>
            <button @click="scrollToSlide" data-target="environment-pillar" class="link active">Environment</button>
          </li>
          <li><button @click="scrollToSlide" data-target="community-pillar" class="link">Community</button></li>
          <li><button @click="scrollToSlide" data-target="people-pillar" class="link">People</button></li>
        </ul>
      </div>
    </nav>
    <section>
      <div class="pillar" id="environment-pillar" tabindex="-1">
        <div class="pillar__content">
          <h4 class="headline1">Environment</h4>
          <p>
            With Electrify America Solar Glow&trade; 1, our first solar farm in Southern California, we’re supporting
            the key sustainability goal of additionality to create brand new renewable energy.
          </p>
          <p>
            <a href="/renewable-energy/" class="button-link"
              >Learn more about Electrify America Solar Glow&trade; 1 <Arrow class="arrow"
            /></a>
          </p>
        </div>
      </div>
      <div class="slide environment-slide">
        <div class="slide__content">
          <p>
            <span class="slide__callout" aria-hidden="true">100%</span>
            Our network is backed by 100% renewable energy via renewable energy certificates.
          </p>
          <impactEnvironment class="slide__image" aria-hidden="true"></impactEnvironment>
        </div>
      </div>
    </section>
    <section>
      <div class="pillar" id="community-pillar" tabindex="-1">
        <div class="pillar__content">
          <h4 class="headline1">Community</h4>
          <p>
            We empower electrification by investing in brand-neutral zero emission vehicle (ZEV) education and other
            programs in disadvantaged, low-income, and rural areas.
          </p>
        </div>
      </div>
      <div class="slide community-slide">
        <div class="slide__content">
          <p>
            <span class="slide__callout">$13 million</span>
            invested in ZEV education and community-based organizations
          </p>
          <impactCommunity class="slide__image" aria-hidden="true"></impactCommunity>
        </div>
      </div>
    </section>

    <section>
      <div class="pillar" id="people-pillar" tabindex="-1">
        <div class="pillar__content">
          <h4 class="headline1">People</h4>
          <p>
            We strive to create a culture grounded in diversity and inclusion, from investing and hiring practices to
            supplier relationships, and strong employee engagement.
          </p>
        </div>
      </div>
      <div class="slide people-slide">
        <div class="slide__content">
          <p>
            <span class="slide__callout">100%</span>
            commitment to workforce and supplier diversity
          </p>
          <impactPeople class="slide__image" aria-hidden="true"></impactPeople>
          <impactPeople class="slide__image slide__image2" aria-hidden="true"></impactPeople>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';
import impactCommunity from '@/assets/images/CSR/OurValues/impact-community.svg';
import impactEnvironment from '@/assets/images/CSR/OurValues/impact-environment.svg';
import impactPeople from '@/assets/images/CSR/OurValues/impact-people.svg';

export default {
  components: {
    Arrow,
    impactEnvironment,
    impactCommunity,
    impactPeople,
  },
  data() {
    return {
      pillarIDs: ['environment-pillar', 'community-pillar', 'people-pillar'],
      slideIDs: ['environment-slide', 'community-slide', 'people-slide'],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.transitionOpacity);
    this.copySlidesToDesktop();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.transitionOpacity);
  },
  methods: {
    copySlidesToDesktop() {
      const desktopSlideContainer = document.getElementsByClassName('slides-container')[0];
      // There was no other way to achieve this layout without copying the inline slide images on mobile to desktop
      this.slideIDs.forEach((slideID, key) => {
        const slide = document.getElementsByClassName(slideID)[0];
        const desktopCopy = slide.cloneNode(true);
        desktopCopy.setAttribute('id', slideID);

        // Auto-show the first or last slide on page load depending on scroll position
        if (
          (desktopSlideContainer.getBoundingClientRect().top >= 0 && key === 0) ||
          (desktopSlideContainer.getBoundingClientRect().top < 0 && key === 2)
        ) {
          desktopCopy.classList.add('active');
        }
        desktopSlideContainer.appendChild(desktopCopy);
      });
    },
    scrollToSlide(event) {
      const id = event.target.dataset.target;
      const target = document.getElementById(id);
      const y = target.getBoundingClientRect().top + window.pageYOffset + -110; // Page offset minus a bit to line up the content
      window.scrollTo({ top: y, behavior: 'smooth' });
      target.focus();
    },
    transitionOpacity() {
      const desktopSlideContainer = document.getElementsByClassName('slides-container')[0];
      if (desktopSlideContainer.getBoundingClientRect().top !== 0) {
        return true;
      }

      const pillarTopPositions = this.pillarIDs.map(
        (pillarID) => document.getElementById(pillarID).getBoundingClientRect().top
      );

      this.pillarIDs.forEach((pillarID, key) => {
        const percentFromTop = (100 * pillarTopPositions[key]) / window.innerHeight;
        const element = document.getElementById(this.slideIDs[key]);
        if (percentFromTop > -45 && percentFromTop <= 50) {
          element.classList.add('active');
          Array.from(document.querySelectorAll('.our-impact__nav .link')).forEach((el) =>
            el.classList.remove('active')
          );
          document.querySelectorAll(`.our-impact__nav [data-target=${pillarID}]`)[0].classList.add('active');
        } else {
          element.classList.remove('active');
        }
      });

      return true;
    },
  },
};
</script>

<style lang="scss">
.our-impact {
  display: block;
  margin: 0 auto;
  padding: 64px 0 0 0;

  h3 {
    margin-bottom: 28px;
  }

  .headline4,
  .pillar {
    padding: 0 16px;

    p {
      font-size: 18px;
    }
  }

  .our-impact__nav ul,
  .slides-container {
    display: none;
  }

  .our-impact__nav-container h3 {
    font-weight: 500;
  }

  .slide {
    display: flex;
    height: 90vw;
    justify-content: center;
    margin: 0 -24px 80px -24px;
    min-height: 320px;
    object-fit: contain;
    overflow: hidden;
    position: relative;
    width: calc(100% + 24px);

    p {
      color: $c-secondary-background;
      font-size: clamp(0.75rem, 3.4vw, 2.5rem);
      font-weight: $fw-regular;
      line-height: 1.25;
      margin: 0 auto;
      padding: 0;
      text-shadow: 2px 3px 5px rgba(255, 255, 255, 0.25);
    }

    .slide__callout {
      display: block;
      font-weight: $fw-medium;
      text-shadow: none;
    }

    .slide__image {
      position: absolute;
    }

    &.environment-slide {
      background-image: linear-gradient(328deg, #009f66 24%, #0f5c75 91%);

      p {
        padding-top: 1em;
        width: 52%;
      }

      .slide__callout {
        font-size: clamp(4.375rem, 20vw, 12.5rem);
      }

      .slide__image {
        bottom: -3%;
        left: 50%;
        opacity: 0.4;
        transform: translate(-50%, 0);
        width: clamp(194px, 52vw, 491px);
      }
    }

    &.community-slide {
      align-items: end;
      background-image: radial-gradient(circle at 50% 31%, #33b4be, #0067a0 76%);

      p {
        padding-bottom: clamp(28px, 2vw, 104px);
        width: 54%;
      }

      .slide__callout {
        font-size: clamp(2.25rem, 10vw, 8rem);
      }

      .slide__image {
        top: 7%;
        left: 50%;
        opacity: 0.5;
        transform: translate(-50%, 0);
        width: clamp(116px, 31vw, 315px);
      }
    }

    &.people-slide {
      align-items: center;
      background-image: linear-gradient(202deg, #1c8cd4 17%, #1c5bb5 83%);

      p {
        width: 55%;
      }

      .slide__callout {
        font-size: clamp(4.375rem, 20vw, 12.5rem);
      }

      .slide__image {
        opacity: 0.25;
        right: 2%;
        top: -10%;
        width: clamp(157px, 42vw, 320px);
      }

      .slide__image2 {
        bottom: -24%;
        left: -10%;
        opacity: 0.1;
        right: unset;
        top: unset;
        width: clamp(200px, 54vw, 500px);
      }
    }
  }
}

@media (min-width: 750px) {
  .our-impact {
    margin-top: -80px;
    padding: 0;
    position: relative;

    .headline4 {
      padding: 0;
    }

    .our-impact__nav,
    .slides-container {
      display: block;
      position: sticky;
      top: 0;
    }

    .our-impact__nav {
      padding-top: 80px;
    }

    .our-impact__nav-container {
      background-color: $c-primary-background;
      background: linear-gradient(180deg, $c-primary-background 78%, rgba(255, 255, 255, 0) 100%);
      padding: 5vw 0 5vw 5vw;
      width: 48%;

      button {
        appearance: none;
        background: $c-primary-background;
        border: 0;
        border-radius: 24px;
        color: $c-focus;
        font-size: 18px;
        margin-right: 10px;
        padding: 6px 12px;

        &.active {
          background: $c-focus;
          color: $c-primary-background;
        }

        &:hover,
        &:focus {
          outline: 3px solid $c-focus;
          outline-offset: 3px;
        }
      }

      ul {
        display: block;
        margin: 0 0 0 -16px;
        padding: 0;

        li {
          display: inline-block;
          list-style: none;
        }
      }
    }

    .pillar {
      align-items: center;
      display: flex;
      height: 90vh;
      outline: none;
      padding: 0 0 0 9vw;
      width: 41vw;

      p {
        font-size: 22px;
      }
    }

    section > .slide {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    .slides-container {
      float: right;
      height: 100vh;
      width: 46%;
      z-index: -1;

      .slide {
        height: calc(100vh - 80px);
        margin: 0;
        opacity: 0;
        right: 0;
        position: absolute;
        top: 80px;
        transition: opacity 300ms ease-in-out;
        width: 100%;

        p {
          font-size: clamp(0.75rem, 2.4vw, 2.5rem);
        }

        &.active {
          opacity: 1;
          transition: opacity 300ms ease-in-out;
        }

        &.environment-slide {
          p {
            padding-top: clamp(28px, 5vw, 104px);
            width: 80%;
          }

          .slide__callout {
            font-size: clamp(4.375rem, 12vw, 12.5rem);
          }
        }

        &.community-slide {
          p {
            padding-bottom: clamp(28px, 5vw, 104px);
            width: 80%;
          }

          .slide__callout {
            font-size: clamp(2.25rem, 6vw, 8rem);
          }

          .slide__image {
            width: clamp(116px, 22vw, 315px);
          }
        }

        &.people-slide {
          p {
            width: 80%;
          }

          .slide__callout {
            font-size: clamp(4.375rem, 12vw, 12.5rem);
          }
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .our-impact {
    .our-impact__nav-container {
      padding: 6vw 0 6vw 9vw;
      width: 42%;

      button {
        font-size: 22px;
        margin-right: 20px;
        padding: 8px 16px;
      }
    }
  }
}
</style>
