<template>
  <div class="insights l-two-col">
    <Card>
      <template #image>
        <picture class="card__image">
          <source
            media="(min-width:750px)"
            srcset="
              @/assets/images/CSR/OurValues/insights/impact-in-action.webp,
              @/assets/images/CSR/OurValues/insights/impact-in-action@2x.webp 2x,
              @/assets/images/CSR/OurValues/insights/impact-in-action@3x.webp 3x
            "
          />
          <source
            media="(max-width:749px)"
            srcset="
              @/assets/images/CSR/OurValues/insights/impact-in-action-mobile.webp,
              @/assets/images/CSR/OurValues/insights/impact-in-action-mobile@2x.webp 2x,
              @/assets/images/CSR/OurValues/insights/impact-in-action-mobile@3x.webp 3x
            "
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/insights/impact-in-action.webp"
            alt="An aerial view of a large field full of solar panels."
          />
        </picture>
      </template>
      <p class="date">March 9, 2023</p>
      <h4>
        <a
          class="button-link"
          href="https://www.linkedin.com/pulse/impact-action-looking-back-pivotal-year-social-responsibility/?trackingId=7fCcYMJiw33ayoaHm5ATXw%3D%3D"
          target="_blank"
          >Impact in Action: Looking Back at a Pivotal Year for Social Responsibility <Arrow class="arrow"
        /></a>
      </h4>
      <p>Electrify America and Electrify Canada have established power names of Hyper-Fast&hellip;</p>
      <p class="byline">
        By
        <a class="link" href="https://www.linkedin.com/in/giovanni-palazzo-384178/" target="_blank">Giovanni Palazzo</a
        >, President &amp; CEO Electrify America
      </p>
    </Card>
    <Card>
      <template #image>
        <picture class="card__image">
          <source
            media="(min-width:750px)"
            srcset="
              @/assets/images/CSR/OurValues/insights/diverse-workforces.webp,
              @/assets/images/CSR/OurValues/insights/diverse-workforces@2x.webp 2x,
              @/assets/images/CSR/OurValues/insights/diverse-workforces@3x.webp 3x
            "
          />
          <source
            media="(max-width:749px)"
            srcset="
              @/assets/images/CSR/OurValues/insights/diverse-workforces-mobile.webp,
              @/assets/images/CSR/OurValues/insights/diverse-workforces-mobile@2x.webp 2x,
              @/assets/images/CSR/OurValues/insights/diverse-workforces-mobile@3x.webp 3x
            "
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/CSR/OurValues/insights/impact-in-action.webp"
            alt="A technician in a bright orange hoodie with a yellow reflective vest over it, wearing a hardhat and a mask. They're bent down looking inside a charger and touching one of the components inside."
          />
        </picture>
      </template>

      <p class="date">February 28, 2023</p>
      <h4>
        <a
          class="button-link"
          href="https://www.linkedin.com/pulse/leading-brands-tomorrow-invest-diverse-workforces-today/"
          target="_blank"
          >The Leading Brands of Tomorrow Invest in Diverse Workforces Today <Arrow class="arrow"
        /></a>
      </h4>
      <p>Electrify America is approaching Diversity, Equity, and Inclusion (DEI) in the workplace.</p>
      <p class="byline">
        By
        <a class="link" href="https://www.linkedin.com/in/jarrod-hough-5a31b6242/" target="_blank">Jarrod Hough</a>,
        Head of HR and People Management
      </p>
    </Card>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';
import Card from '@/components/Card/Card.vue';

export default {
  components: {
    Arrow,

    Card,
  },
};
</script>

<style lang="scss">
.insights {
  .card__image {
    flex: 1 0 151px;
  }

  .byline,
  .date {
    color: $c-gray-dark;
    font-size: 14px;
    font-weight: $fw-light;
    line-height: 1.5;
    margin-top: 0;
  }

  .card {
    max-width: 500px;

    @media (min-width: 1030px) {
      max-width: 100%;
    }
  }
}
</style>
